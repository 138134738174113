import React, { useState, useEffect, createContext, useContext } from "react";
import { Link } from "react-router-dom";
import {
  useIntl,
  FormattedHTMLMessage,
  FormattedMessage,
  FormattedNumber,
  FormattedDate,
} from "react-intl";
import TopNav from "./../navigation/TopNav";
import TopHeader from "./../navigation/TopHeader";
import Icon from "./../utils/Icon";
import GreenDot from "./../utils/GreenDot";
import RedDot from "./../utils/RedDot";
import AmberDot from "./../utils/AmberDot";
import OperatorList from "./OperatorList";
import MarketRate from "./MarketRate";
import FilterList from "./FilterList";
import Loader from "./../utils/Loader";
import Legend from "./Legend";
import BottomNavigation from "./../navigation/BottomNavigation";
import { AppContext } from "./../App";
import endpoint from "../../api";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export const CompareListContext = createContext();
const BACK_BUTTON_KEY = "saverpacific.back_button";
const OPERATORS_KEY = "saverpacific.operators";
const COUNTRY_FROM_KEY = "saverpacific.countryFrom";
const COUNTRY_TO_KEY = "saverpacific.countryTo";

function CompareList({ match }) {
  const { selectedLanguage } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [operatorsCopy, setOperatorsCopy] = useState([]);
  const [operators, setOperators] = useState([]);
  const [marketRate, setMarketRate] = useState({ rate: 0, currency: null });
  const [filters, setFilters] = useState([]);
  const [legend, setLegend] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const CompareListContextValue = {
    handleFilteredAdd,
    handleFilteredRemove,
    filters,
  };

  function countryFromLabel() {
    let k = localStorage.getItem(COUNTRY_FROM_KEY);
    return JSON.parse(k).label;
  }

  function countryToLabel() {
    let k = localStorage.getItem(COUNTRY_TO_KEY);
    return JSON.parse(k).label;
  }

  useEffect(() => {
    setIsLoading(true);
    endpoint
      .get(
        `/${selectedLanguage.value}/quote/${match.params.countryFrom}/${match.params.countryTo}/${match.params.amount}`
      )
      .then((response) => {
        setData(response.data);
        setOperators(response.data.results);
        setOperatorsCopy(response.data.results);
        setMarketRate(response.data.market_rate);
        setFilters(response.data.filters);
        setLegend(response.data.legend);
        setIsLoading(false);
      });
    localStorage.setItem(
      BACK_BUTTON_KEY,
      `/compare/${match.params.currencyFrom}/${match.params.countryFrom}/${match.params.countryTo}/${match.params.amount}`
    );
  }, []);

  function handleFilteredAdd(filter) {
    const newFiltered = [...filtered];
    const index = newFiltered.findIndex((f) => f.name === filter.name);
    newFiltered[index] = filter;
    index !== -1
      ? setFiltered(newFiltered)
      : setFiltered([...filtered, filter]);
  }

  function handleFilteredRemove(name) {
    setFiltered(filtered.filter((f) => f.name !== name));
  }

  function removeDuplicates(array) {
    array.map(function (item, index) {
      var findIndex = array.findIndex(function (find) {
        return item.ServiceId == find.ServiceId;
      });

      if (findIndex !== index) {
        array.splice(index, 1);
      }
    });

    return array;
  }

  useEffect(() => {
    localStorage.setItem(OPERATORS_KEY, JSON.stringify(operators));
  }, [operators]);

  useEffect(() => {
    let filteredOperators = operatorsCopy;

    if (filtered.length > 0) {
      filtered.map((filter) => {
        switch (filter.type) {
          case "sort":
            break;
          case "filter":
            let found = [];

            filter.fields.map((field) => {
              filteredOperators.map((operator) => {
                if (Array.isArray(operator[field])) {
                  if (
                    operator[field].includes(filter.order + "_" + filter.value)
                  ) {
                    found.push(operator);
                  }
                } else {
                  if (operator[field] == filter.order + "_" + filter.value) {
                    found.push(operator);
                  }
                }
              });
            });
            filteredOperators = removeDuplicates(found);
            break;
          default:
            break;
        }
      });

      filtered.map(function (filter) {
        switch (filter.type) {
          case "sort":
            filteredOperators.sort((a, b) => {
              var y = -2;
              if (typeof a[filter.value] === "string") {
                y =
                  filter.sortby == "asc"
                    ? a[filter.value].toLowerCase() <
                      b[filter.value].toLowerCase()
                      ? -1
                      : a[filter.value].toLowerCase() >
                        b[filter.value].toLowerCase()
                      ? 1
                      : 0
                    : a[filter.value].toLowerCase() >
                      b[filter.value].toLowerCase()
                    ? -1
                    : a[filter.value].toLowerCase() <
                      b[filter.value].toLowerCase()
                    ? 1
                    : 0;
              } else {
                y =
                  filter.sortby == "asc"
                    ? a[filter.value] < b[filter.value]
                      ? -1
                      : a[filter.value] > b[filter.value]
                      ? 1
                      : 0
                    : a[filter.value] > b[filter.value]
                    ? -1
                    : a[filter.value] < b[filter.value]
                    ? 1
                    : 0;
              }
              return y;
            });
            break;
          case "filter":
            break;
        }
      });

      filteredOperators = [...new Set(filteredOperators)];
    }

    setOperators(filteredOperators);
  }, [filtered]);

  return (
    <div className="screen-container pagination">
      <TopNav>
        <Link to={"/compare"}>
          <Icon icon={"back"} />
        </Link>
        <h1>
          {match.params.countryFrom}
          <Icon icon={"arrow_right"} />
          {match.params.countryTo}
          <Icon icon={"arrow_right"} />
          <FormattedNumber
            value={match.params.amount}
            style="currency"
            currency={match.params.currencyFrom}
          />
        </h1>
      </TopNav>
      {!isLoading && (
        <TopHeader>
          <h2>
            <FormattedMessage id="topheader.compare.list.h2" />
          </h2>
          <h3>
            <FormattedMessage
              id="topheader.compare.list.p"
              values={{
                country_from: countryFromLabel(),
                country_to: countryToLabel(),
              }}
            />
          </h3>
          {data.date_today != null && (
            <p>
              <span class="type-icon" data-for="live-results">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              </span>
              <span>
                <FormattedMessage
                  id="topheader.compare.list.green"
                  values={{
                    date_today: data.date_today,
                  }}
                />
              </span>
            </p>
          )}
          {data.fortnightly_date_updated != null && (
            <p>
              <span class="type-icon" data-for="fortnightly-results">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              </span>
              <span>
                <FormattedMessage
                  id="topheader.compare.list.amber"
                  values={{
                    date_updated: data.fortnightly_date_updated,
                  }}
                />
              </span>
            </p>
          )}
          {data.mystery_date_updated != null && (
            <p>
              <span class="type-icon" data-for="mystery-shopping-results">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              </span>
              <span>
                <FormattedMessage
                  id="topheader.compare.list.red"
                  values={{
                    date_updated: data.mystery_date_updated,
                  }}
                />
              </span>{" "}
            </p>
          )}
          <p>
            <br />
            <Link to="/video">
              <b>
                <FormattedMessage id="topheader.compare.help" />
              </b>
            </Link>
          </p>
        </TopHeader>
      )}
      <div className="screen-content">
        <div className="container">
          {isLoading && <Loader />}
          <CompareListContext.Provider value={CompareListContextValue}>
            {!isLoading && <FilterList filters={filters} />}
            {!isLoading && <MarketRate marketRate={marketRate} />}
            {!isLoading && <OperatorList operators={operators} />}
            {!isLoading && (
              <p>
                <FormattedMessage id="bottomheader.compare.list.p" />
              </p>
            )}
            {!isLoading && (
              <a
                href={data.view_operators}
                className="btn btn--primary"
                target="_blank"
              >
                <FormattedMessage id="button.view_all_operators" />
              </a>
            )}
          </CompareListContext.Provider>
        </div>
      </div>
      {!isLoading && <Legend legend={legend} />}
      <BottomNavigation />
    </div>
  );
}

export default CompareList;
