import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Logo from "./../utils/LogoWhite";
import SaverPacificLogo from "../../assets/img/saverpacific-logo.svg";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Splash() {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <div className="screen-container splash">
      <div className="container">
        <div className="screen-content">
          <div className="screen-content--center">
            <img src={SaverPacificLogo} alt="SaverPacific" />
          </div>
          <Logo />
        </div>
      </div>
      {!loading && <Redirect to="/home" />}
    </div>
  );
}
