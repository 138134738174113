import React from "react";
import { FormattedMessage } from "react-intl";
//import SouthLogo from "../../assets/img/360south-logo.svg";
//import DMALogo from "../../assets/img/dma-logo.svg";
import SaverGlobal from "../../assets/img/saver-global-logo.svg";

export default function Logo() {
  return (
    <>
      <div className="logo-container">
        <h3>
          <FormattedMessage id="logo.managed.h3" />
        </h3>
        <img src={SaverGlobal} alt="Saver Global" />
        {/* <img src={DMALogo} alt="DMA" /> */}
        {/* <img src={SouthLogo} alt="360South" /> */}
      </div>
    </>
  );
}
