import React, { useContext } from "react";
import Icon from "../utils/Icon";
import { Card, CardHeader, CardContent } from "../utils/Card";
import PayPeriodSelect from "../form/PayPeriodSelect";
import { FormattedMessage } from "react-intl";
import { BudgetContext } from "./index";
import { AppContext } from "./../App";

export default function Expense(props) {
  const { handleExpenseChange, handleExpenseDelete } =
    useContext(BudgetContext);
  const { currency } = useContext(AppContext);

  const { id, name, amount, pay_period } = props;

  function handleChange(name, value) {
    let changes = { [name]: value };
    handleExpenseChange(id, { ...props, ...changes });
  }

  return (
    <React.Fragment>
      <Card className={"card--white"}>
        <CardContent>
          <CardHeader>
            <h3>
              <FormattedMessage id="label.item" />
            </h3>
            <span onClick={() => handleExpenseDelete(id)}>
              <Icon icon={"clear"} width={24} height={24} />{" "}
              <FormattedMessage id="button.remove" />
            </span>
          </CardHeader>
          <div className="form-group">
            <label className="label-control hidden" htmlFor="name">
              <FormattedMessage id="label.item" />
            </label>
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="label-control" htmlFor="amount">
                <FormattedMessage id="label.amount" />
              </label>
              <div className="input-group">
                <span className="input-currency">{currency}</span>
                <input
                  className="form-control"
                  id="amount"
                  name="amount"
                  type="number"
                  min="0"
                  step="0.05"
                  value={amount}
                  onChange={(e) =>
                    handleChange("amount", parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-control" htmlFor="basic-wage">
                <FormattedMessage id="label.pay_period" />
              </label>
              <PayPeriodSelect
                name="pay_period"
                value={pay_period}
                onChange={handleChange}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
