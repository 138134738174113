import React, { useEffect, useState, useContext } from "react";
import TopNav from "./../navigation/TopNav";
import { FormattedMessage } from "react-intl";
import BottomNavigation from "./../navigation/BottomNavigation";
import Accordion from "./../utils/Accordion";
import Loader from "./../utils/Loader";
import { AppContext } from "./../App";
import endpoint from "../../api";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Faq() {
  const { selectedLanguage, selectedSite } = useContext(AppContext);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    endpoint
      .get(`/${selectedSite}/${selectedLanguage.value}/faq/`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  }, [selectedLanguage.value]);

  return (
    <div className="screen-container">
      <TopNav>
        <h1>
          <FormattedMessage id="topnav.faq.h1" />
        </h1>
      </TopNav>
      <div className="screen-content">
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            data.map((item, index) => <Accordion key={index} {...item} />)
          )}
          {!loading && (
            <p>
              <FormattedMessage
                id="bottom.faq.p"
                values={{
                  a: (...chunks) => (
                    <a href="mailto:enquiries@saver.global">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
          )}
        </div>
      </div>
      <BottomNavigation />
    </div>
  );
}
